module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-50360426-2","send_page_view":false,"allowAdFeatures":true},"facebookPixel":{"pixelId":"777226563389066"},"linkedin":{"trackingId":"377778","cookieName":"gatsby-gdpr-linked-in"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-reddit-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"t2_hvqyr8fh"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Afosto","short_name":"Afosto","description":"Afosto","start_url":"/","background_color":"#fff","theme_color":"#005eff","display":"standalone","cache_busting_mode":"none","icons":[{"src":"/img/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/img/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/img/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"/img/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/img/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Graphik"]}},
    }]
