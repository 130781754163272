// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-partners-index-js": () => import("./../../../src/pages/about-us/partners/index.js" /* webpackChunkName: "component---src-pages-about-us-partners-index-js" */),
  "component---src-pages-apps-instant-search-demo-js": () => import("./../../../src/pages/apps/instant-search/demo.js" /* webpackChunkName: "component---src-pages-apps-instant-search-demo-js" */),
  "component---src-pages-apps-instant-search-widget-js": () => import("./../../../src/pages/apps/instant-search/widget.js" /* webpackChunkName: "component---src-pages-apps-instant-search-widget-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-developers-integrations-js": () => import("./../../../src/pages/developers/integrations.js" /* webpackChunkName: "component---src-pages-developers-integrations-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-nl-apps-instant-search-demo-js": () => import("./../../../src/pages/nl/apps/instant-search/demo.js" /* webpackChunkName: "component---src-pages-nl-apps-instant-search-demo-js" */),
  "component---src-pages-nl-apps-instant-search-widget-js": () => import("./../../../src/pages/nl/apps/instant-search/widget.js" /* webpackChunkName: "component---src-pages-nl-apps-instant-search-widget-js" */),
  "component---src-pages-nl-blog-js": () => import("./../../../src/pages/nl/blog.js" /* webpackChunkName: "component---src-pages-nl-blog-js" */),
  "component---src-pages-nl-contact-js": () => import("./../../../src/pages/nl/contact.js" /* webpackChunkName: "component---src-pages-nl-contact-js" */),
  "component---src-pages-nl-developers-koppelingen-js": () => import("./../../../src/pages/nl/developers/koppelingen.js" /* webpackChunkName: "component---src-pages-nl-developers-koppelingen-js" */),
  "component---src-pages-nl-docs-js": () => import("./../../../src/pages/nl/docs.js" /* webpackChunkName: "component---src-pages-nl-docs-js" */),
  "component---src-pages-nl-over-ons-partners-index-js": () => import("./../../../src/pages/nl/over-ons/partners/index.js" /* webpackChunkName: "component---src-pages-nl-over-ons-partners-index-js" */),
  "component---src-templates-analyse-page-index-js": () => import("./../../../src/templates/analyse-page/index.js" /* webpackChunkName: "component---src-templates-analyse-page-index-js" */),
  "component---src-templates-blocks-page-index-js": () => import("./../../../src/templates/blocks-page/index.js" /* webpackChunkName: "component---src-templates-blocks-page-index-js" */),
  "component---src-templates-contact-page-index-js": () => import("./../../../src/templates/contact-page/index.js" /* webpackChunkName: "component---src-templates-contact-page-index-js" */),
  "component---src-templates-docs-page-index-js": () => import("./../../../src/templates/docs-page/index.js" /* webpackChunkName: "component---src-templates-docs-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-pricing-page-index-js": () => import("./../../../src/templates/pricing-page/index.js" /* webpackChunkName: "component---src-templates-pricing-page-index-js" */),
  "component---src-templates-register-page-index-js": () => import("./../../../src/templates/register-page/index.js" /* webpackChunkName: "component---src-templates-register-page-index-js" */)
}

